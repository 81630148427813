<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12" style="padding: 0 !important;">
        <MarketHeader :data="$route.params.name"></MarketHeader>
        <!-- <div class="market-main-sub-tabs w-scroll-style">
            <div v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" :class="'market-main-sub-tab-item ' + (item.name == tabName ? 'active' : '') ">
                 <router-link :to="{name: item.name, params: { name: $route.params.name }, }">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div> -->
    </div>
    <MarketPerformance></MarketPerformance>
</div>
</template>


<style>
    @media (max-width: 1220px) {
        .tgju-widget .table-mobile-grid tbody tr td:nth-child(1)::before {
            content: 'نام : ' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(2)::before {
            content: 'روز' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(3)::before {
            content: 'یک هفته' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(4)::before {
            content: 'یک ماه' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(5)::before {
            content: 'شش ماه' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(6)::before {
            content: 'یک سال' !important;
        }
        .tgju-widget .table-mobile-grid tbody tr td:nth-child(7)::before {
            content: 'سه سال' !important;
        }
    }
    .google-visualization-tooltip {
        direction: ltr;
        text-align: left;
    }	
    .pcw-geomap-tooltip>div {
        display: flex;
    }
    .pcw-geomap-tooltip>div span:first-child {
        display: flex;
        flex-grow: 1;
    }
    .pcw-geomap-tooltip>div span:last-child {
        float: left;
        margin-left: 0;
    }
    .google-visualization-tooltip-item-list .google-visualization-tooltip-item:first-child {
        margin: 0.8em 0em 1em 0em;
        text-align: center;
    }
    .google-visualization-tooltip-item:first-child span{
        font-size: 18px;
    }

    .pcw-geomap-tooltip>div {
        padding: 6px 0;
    }
    html.dark  .pcw-geomap rect[x="0"] {
        fill: #333;
    }
</style>

<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش اولیه مورد استفاده قرار میگیرد
import MarketPerformance from "@/components/Mobile/Market/Profile/Performance";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Mobile/Market/Profile/Header';

export default {
    name: "marketPerformance",
    components: {
        MarketPerformance,
        MarketHeader,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
            tabName:'MarketPerformance',
        };
    },
    mounted() {
    },
};
</script>
